.p1nuqav8{margin-bottom:3rem;height:30vh;width:100%;position:relative;}@media only screen and (min-width:450px){.p1nuqav8{margin-bottom:4rem;}}@media only screen and (min-width:500px){.p1nuqav8{margin-bottom:5rem;}}@media only screen and (min-width:540px){.p1nuqav8{margin-bottom:8rem;}}@media only screen and (min-width:600px){.p1nuqav8{margin-bottom:10rem;}}@media only screen and (min-width:600px){.p1nuqav8{margin-bottom:10rem;}}@media only screen and (min-width:650px){.p1nuqav8{margin-bottom:12rem;}}@media only screen and (min-width:700px){.p1nuqav8{margin-bottom:14rem;}}@media screen and (min-width:768px){.p1nuqav8{margin-bottom:50vw;height:15vh;}}@media screen and (min-width:1024px){.p1nuqav8{margin-bottom:-20vh;height:300vh;}}@media screen and (orientation:landscape) and (max-width:1024px ) and (max-height:600px){.p1nuqav8{margin-bottom:55vw;}}@media screen and (min-width:1024px) and (max-width:1440px ) and (min-height:1000px) and (orientation:portrait){.p1nuqav8{margin-bottom:-50vh;height:200vh;}}@media screen and (max-width:1440px ) and (min-height:1000px) and (orientation:landscape){.p1nuqav8{margin-bottom:-20vw;height:300vh;}}@media screen and (min-width:1440px){.p1nuqav8{margin-bottom:0;}}@media screen and (min-width:1700px){.p1nuqav8{margin-bottom:-3vw;}}.p1nuqav8[data-visibility='true']{opacity:1;}
.o1asw6lg{background-repeat:no-repeat;position:absolute;top:-0.25rem;height:calc(100% + 0.25rem);width:100%;background-position:top;background-size:110%;}
.e1wfb9ha{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;position:absolute;top:0;right:0;padding:1.5rem;}@media screen and (min-width:1440px){.e1wfb9ha{padding:3rem;}}
.e1d43wt7{color:white;font-size:0.875rem;font-weight:600;}@media screen and (min-width:1024px){.e1d43wt7{font-size:1rem;}}
.dhf39w1{height:0.4375rem;width:0.4375rem;background:white;border-radius:100%;margin-top:2px;margin-left:0.5rem;display:block;-webkit-animation:pulse-dhf39w1 1.5s linear infinite;animation:pulse-dhf39w1 1.5s linear infinite;}@-webkit-keyframes pulse-dhf39w1{0%{opacity:1;}30%{opacity:0;}50%{opacity:0.6;}80%{opacity:0;}100%{opacity:1;}}@keyframes pulse-dhf39w1{0%{opacity:1;}30%{opacity:0;}50%{opacity:0.6;}80%{opacity:0;}100%{opacity:1;}}
.ee0ec0u{position:absolute;width:100%;top:-0.25rem;left:0;}
.e1otoc6e{background-repeat:no-repeat;background-position:top;background-size:110%;width:100%;height:calc(100% + 0.25rem);}
.ezzbozy{height:1px;background:white;width:100%;box-shadow:0px 1.06174px 4.24695px rgba(255,255,255,0.2);}
.m1sae9ty{position:relative;background:rgba(255,255,255,0.4);border-radius:0.5rem;width:calc(100% - 2rem);margin:0 auto;max-width:120rem;padding-top:55%;-webkit-transform-style:preserve-3d;-ms-transform-style:preserve-3d;transform-style:preserve-3d;will-change:transform;overflow:hidden;box-shadow:0px 54px 67px rgba(0,0,0,0.11),0px 22.5599px 44.5415px rgba(0,0,0,0.079074), 0px 12.0616px 22.6816px rgba(0,0,0,0.0655718),0px 6.76164px 10.537px rgba(0,0,0,0.055), 0px 6.76164px 10.537px rgba(0,0,0,0.055),0px 1.49432px 1.93392px rgba(0,0,0,0.030926);}@media screen and (min-width:768px){.m1sae9ty{width:calc(100% - 10rem);border-radius:0.5rem;}}@media screen and (min-width:1024px){.m1sae9ty{width:80vw;padding-top:45%;}}@media screen and (min-width:1440px){.m1sae9ty{width:70vw;padding-top:45%;}}@media screen and (min-width:1900px){.m1sae9ty{width:70vw;padding-top:40%;}}
.cet5raf{position:relative;height:100vh;width:100%;-webkit-perspective:36rem;-moz-perspective:36rem;-ms-perspective:36rem;perspective:36rem;-webkit-perspective-origin:50% 50%;-moz-perspective-origin:50% 50%;-ms-perspective-origin:50% 50%;perspective-origin:50% 50%;-webkit-transform:perspective(0px);-ms-transform:perspective(0px);transform:perspective(0px);}@media screen and (min-width:1024px){.cet5raf{-webkit-perspective:56rem;-moz-perspective:56rem;-ms-perspective:56rem;perspective:56rem;position:-webkit-sticky;position:sticky;top:20vh;}}@media screen and (min-width:1440px){.cet5raf{top:calc(5vh + 5rem);}}
